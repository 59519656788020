import React, { useContext, useState, useEffect, Suspense, useCallback, useMemo } from 'react';
import { useIntl } from "react-intl";

import packageJson from '../package.json';
import preval from 'preval.macro';

import { ConfigProvider, theme as AntTheme, message, Modal } from 'antd';

import { EnumErrorMessage, EnumWebSocketCmd, EnumActionType, EnumThemeType } from './modules/ASUtils/ASConfig';
import { AppContext, AppContextProvider, Constants } from './Utils';
import { LoadingMask } from './modules/Common/Common';
import './App.css';

const MainApp = React.lazy(() => import('./modules/MainApp/MainApp'));

const dateTimeStamp = preval`module.exports = new Date().toLocaleDateString() + ' ' + new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0') + ':' + new Date().getSeconds().toString().padStart(2, '0');`

function Application({onThemeChange}) {
	const { verifyUser, ajaxLogin, accountInfo, sendWS, addWSNotification, removeWSNotification } = useContext(AppContext);
	const intl = useIntl();
	const [isLogin, setIsLogin] = useState(false);
	const [errMsg, setErrMsg] = useState('');


	useEffect(() => {
		// create meta tags
		createMeta('version', packageJson.version);
		createMeta('build-time', dateTimeStamp);

		verifyUser((accountInfo, supportAccess) => {
			setIsLogin(true);

			if (supportAccess) {
				ajaxLogin((success, accountInfo) => {
					if (success) {
						// setTimeout(() => {	// must get device list for lockdown status
						// 	context.getDeviceList();
						// }, 1000);
					} else {
						setErrMsg(accountInfo);
					}
				});
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		var appTheme = EnumThemeType.Light;
		document.body.classList.remove('dark');
		if (accountInfo.theme === EnumThemeType.Dark ||
			(accountInfo.theme === EnumThemeType.OSDefault && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
				
			document.body.classList.add('dark');
			appTheme = EnumThemeType.Dark;
		}
		onThemeChange(appTheme);
	}, [accountInfo.theme, onThemeChange]);

	useEffect(() => {
		if (errMsg) {
			message.error(EnumErrorMessage[errMsg] ? intl.formatMessage({id: EnumErrorMessage[errMsg]}) : errMsg);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errMsg]);
	
	const createMeta = (name, content) => {
		var meta = document.createElement('meta');
		meta.name = name;
		meta.content = content;
		document.head.append(meta);
	};

	const receiveNotification = useCallback(data => {
		const doLogout = () => {
			sendWS({cmd_id: EnumWebSocketCmd.ACCOUNT_LOGOUT});
			window.localStorage.removeItem(Constants.storageNames.accountInfo);
			window.location = `${Constants.gvCloudUrl}logout?redirect=/map&pathname=${window.location.pathname}`;
		};
		const doRefresh = () => {
			window.location.reload();
		};

		const alertMsg = (msg, callback) => {
			callback = callback || function() {};
			Modal.warning({
				title: process.env.REACT_APP_SITE_TITLE,
				content: msg,
				centered: true,
				afterClose: callback
			});
			setTimeout(callback, 10 * 1000);
		};

		switch (data.cmd_id) {
			case EnumWebSocketCmd.ACCOUNT_DATA_CHANGED:
				var action_type = parseInt(data.data1);
				if (action_type === EnumActionType.Delete) {
					alertMsg(intl.formatMessage({id: 'account_been_deleted'}), doLogout);
				} else if (action_type === EnumActionType.Edit) {
					alertMsg(intl.formatMessage({id: 'privilege_changed_reload_page'}), doRefresh);
				}
				break;
			
			case EnumWebSocketCmd.VMS_WEBSOCKET:
				if (data.action === 'LOGOUT') {
					if (data.type === 'SERVER_UPDATE' && process.env.REACT_APP_TYPE === 'dev') return;	// vms server maintain not logout in dev environment
					alertMsg(intl.formatMessage({id: `VMS_${data.type}`}), doLogout);

				} else if (data.type === 'ACCOUNT_USER_CHANGE' && data.id === accountInfo.id) {
					if (data.action === 'DELETE') {
						alertMsg(intl.formatMessage({id: 'account_been_deleted'}), doLogout);

					} else if (data.action === 'UPDATE' && data.master !== undefined && data.master !== accountInfo.master) {
						alertMsg(intl.formatMessage({id: 'privilege_changed_reload_page'}), doRefresh);
					}
				} else if (data.type === 'USER_PRIVILEGES_CHANGE') {
					alertMsg(intl.formatMessage({id: 'privilege_changed_reload_page'}), doRefresh);
				}
				break;

			case EnumWebSocketCmd.ABNORMAL_CLOSE_WEBSOCKET:
				const storageName = 'lastAbnormalClosed';
				const lastAbnormalClosed = parseInt(window.localStorage.getItem(storageName)) || 0;
				window.localStorage.setItem(storageName, new Date().getTime());
				Modal.warning({
					title: process.env.REACT_APP_SITE_TITLE,
					content: intl.formatMessage({id: 'connection_closed_abnormally'}),
					centered: true,
					afterClose: () => {
						window.localStorage.removeItem(storageName);
						window.location.reload();
					}
				});
				if (!lastAbnormalClosed || (new Date().getTime()) - lastAbnormalClosed > 10 * 60 * 1000) {
					setTimeout(() => {
						window.location.reload();
					}, 10 * 1000);
				}

				break;
			default:
				break;
		}
	}, [intl, accountInfo, sendWS]);

	useEffect(() => {
		if (isLogin) {
			addWSNotification(receiveNotification);
		}
		return () => {
			if (isLogin) {
				removeWSNotification(receiveNotification);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogin, receiveNotification]);

	return (
		isLogin ?
		<div className='App'>
			{
				process.env.REACT_APP_TYPE !== 'release' &&
				<div className='app-debug'>{`${process.env.REACT_APP_TYPE}-${packageJson.version}-${dateTimeStamp}`}</div>
			}
			<Suspense fallback={<LoadingMask show={true} />}>
				<MainApp />
			</Suspense>
		</div>
		:
		<LoadingMask show={true} />
	);
}
Application.defaultProps = {
	onThemeChange: (theme) => {}
};

export default function App() {
	const [theme, setTheme] = useState(EnumThemeType.Light);
	
	const themeToken = useMemo(() => {
		if (theme === EnumThemeType.Dark) {
			return {
				colorTextBase: '#d9d9d9',
				colorBgBase: '#313336',
				colorPrimary: '#4C94E9'
			};
		} else {
			return {
				colorTextBase: '#282828',
				colorBgBase: '#fff',
				colorPrimary: '#5474A5'
			};
		}
	}, [theme]);

	return (
		<ConfigProvider
			theme={{
				algorithm: theme === EnumThemeType.Dark ? AntTheme.darkAlgorithm : AntTheme.defaultAlgorithm,
				token: {
					fontSize: 14,
					fontFamily: `'Microsoft JhengHei', 微軟正黑體, 'Segoe UI', SegoeUI, Helvetica, Arial, sans-serif`,
					borderRadius: 6,
					...themeToken
				},
			}}
		>
			<AppContextProvider>
				<Application onThemeChange={(val) => setTheme(val)}/>
			</AppContextProvider>
		</ConfigProvider>
	);
}

